import {CHART_ACCOUNT} from "../../constant/ActionType";

export default function ChrAccountReducer(state = {
    chartAccount:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case CHART_ACCOUNT:
			return { ...state,
				chartAccount: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
