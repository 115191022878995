import {ALL_PAYROLL_ALLOWANCE} from "../../constant/ActionType";

export default function PayrollAllowance(state = {
    allowanceData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case ALL_PAYROLL_ALLOWANCE:
			return { ...state,
				allowanceData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}







