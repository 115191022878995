import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { fetchRegisterCompany } from "../../store/action/index";
import { connect } from "react-redux";
import ThemeInput from "../inputs/ThemeInput";
import { Spin, Upload } from "antd";
import { dispatch } from "../../store/index";
import { useLocation } from "react-router";
import ClearIcon from '@mui/icons-material/Clear';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  backgroundColor: "white",
  boxShadow: 24,
  maxHeight: 500,
  overflowY: "hidden",
  overflowX: "hidden",
  border: "none",
  p: 4,
};

const AddCompanyModal = ({ open, handleClose }) => {
  const location = useLocation();
  let userId = localStorage.getItem("Id");

  const [loading, setLoading] = useState(false);
  const [company_type, setcompany_type] = useState(
    location.state !== null ? location.state?.company_type : ""
  );

  const [company_address, setCompanyAddress] = useState(
    location.state !== null ? location.state?.company_address : ""
  );

  const [company_phone, setCompanyPhone] = useState(
    location.state !== null ? location.state?.phone_number : ""
  );

  const [company_name, setCompanyName] = useState(
    location.state !== null ? location.state?.company_name : ""
  );
  const [company_email, setCompanyEmail] = useState(
    location.state !== null ? location.state?.company_email : ""
  );

  const handleSubmit = () => {
    dispatch(
      fetchRegisterCompany({
        company_address: company_address,
        company_phone: company_phone,
        company_name: company_name,
        company_email: company_email,
        company_type: company_type,
        setLoading: setLoading,
      })
    );
  };

  const CLoseModal=()=>{
    handleClose()
  }

  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
      <ClearIcon style={{ cursor: 'pointer',position:"relative",right:"20px",top:"-20px" }} onClick={CLoseModal} />

        <Spin spinning={loading}>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <div className="row">
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="text"
                    name="Company Name*"
                    value={company_name}
                    placeholder="Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    name="Company Email"
                    type="text"
                    value={company_email}
                    placeholder="Company Email"
                    onChange={(e) => setCompanyEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    name="Phone Number*"
                    type="number"
                    placeholder="Phone Number"
                    value={company_phone}
                    onChange={(e) => setCompanyPhone(e.target.value)}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    name="Company Address*"
                    type="text"
                    placeholder="Company Address"
                    value={company_address}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                  />
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Company Type*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        displayEmpty
                        input={<OutlinedInput />}
                        style={{ height: "52px" }}
                        value={company_type}
                        onChange={(e) => setcompany_type(e.target.value)}
                      >
                        <MenuItem disabled value="">
                          <em>Select Company Type</em>
                        </MenuItem>

                        <MenuItem value="customer">customer</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-12"
                  style={{ display: "flex", justifyContent: "right" }}
                >
                  <Button
                    onClick={handleSubmit}
                    disableElevation
                    className="px-3"
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Save Company
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Spin>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(AddCompanyModal);
