import { CHALLANS_REPORTS } from "../../constant/ActionType";

export default function dashboardallchallanreports(state = {
    challanreportsget: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case CHALLANS_REPORTS:
            return {
                ...state,
                challanreportsget: action.data,
                statuscode: action.status
            };

        default:
            console.log("checkingg", action)
    }
    return state;
}
