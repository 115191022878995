import {INVOICE_ALLVIEW} from "../../constant/ActionType";

export default function invoicegetAllReducer(state = {
    invoiceget:[],
    statuscode: null,
}, action) {
    switch (action.type) {    
        case INVOICE_ALLVIEW:
			return { ...state,
				invoiceget: action.data ,
                statuscode:action.status
            };

        default:
    }
    return state;
}
