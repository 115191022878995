import {LEAVE_MANAGEMENT_GET} from "../../constant/ActionType";

export default function LeavesReducer(state = {
    leavesData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case LEAVE_MANAGEMENT_GET:
			return { ...state,
				leavesData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
