import { Popconfirm } from "antd";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import { EditOutlined } from "@ant-design/icons";

// let permissionUser = localStorage.getItem('LoginData');
// let permissions = [];

// try {
//   permissions = JSON.parse(permissionUser)?.permissions || [];
// } catch (error) {
//   console.error('Error parsing user permissions:', error);
// }

// const allowedPermissions = permissions.map((permission) => permission?.permission);

const RenderEditButton = (r, p, cb) => {
  return (
    // <Button className="m3-1" type="primary" variant="contained" onClick={() => cb(p, r)}>
    //   <EditOutlined />
    // </Button>

    <IconButton style={{ margin: "0px 8px" }} onClick={() => cb(p, r)}>
      <EditOutlined style={{ color: "#6aa4b7 " }} />
    </IconButton>
  );
};

const RenderDeactivateButton = (record, cb) => {
  return (
    <Popconfirm
      title={`Are you sure you want to deactivate?`}
      onConfirm={() => cb(record)}
    >
      <IconButton variant="contained">
        <CloseIcon style={{ color: "#E03F45" }} />
      </IconButton>
    </Popconfirm>
  );
};

const RenderActivateButton = (record, cb) => {
  return (
    <Popconfirm
      title={`Are you sure you want to Active?`}
      onConfirm={() => cb(record)}
    >
      <IconButton variant="contained">
        <CheckIcon style={{ color: "green" }} />
      </IconButton>
    </Popconfirm>
  );
};

const RenderButton = (link, lable) => {
  return (
    <Link to={link}>
      <Button
        sx={{ margin: "10px" }}
        disableElevation
        size="medium"
        type="submit"
        variant="contained"
        // color="primary"
      >
        {lable}
      </Button>
    </Link>
  );
};

export const editButton = (permission, path, record, cb) =>
  // allowedPermissions.includes(permission) ?
  RenderEditButton(record, path, cb); // : null;
export const deactivateButton = (permission, record, cb) =>
  // allowedPermissions.includes(permission) ?
  RenderDeactivateButton(record, cb); // : null;
export const activateButton = (permission, record, cb) =>
  // allowedPermissions.includes(permission) ?
  RenderActivateButton(record, cb); // : null;
export const renderButton = (permission, link, lable) =>
  //  (allowedPermissions.includes(permission) ?
  RenderButton(link, lable); //: null);
