import { GET_EMPLOYEE_SALARY } from "../../constant/ActionType";

export default function EmpSalaryReducer(
  state = {
    empsalaray: [],
    statusCode: null,
  },
  action
) {
  switch (action.type) {
    case GET_EMPLOYEE_SALARY:
      return { ...state, empsalaray: action?.data, statusCode: action.status };

    default:
  }
  return state;
}
