import {GET_ALL_PAYSLIP} from "../../constant/ActionType";

export default function PayrollsPayslip(state = {
    payslipData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case GET_ALL_PAYSLIP:
			return { ...state,
				payslipData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}







