// type
import { Key, Text, Colorfilter, Barcode } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Key,
  typography: Text,
  color: Colorfilter,
  shadow: Barcode,
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissionsArray = JSON.parse(permissionUser)?.permissions;

// const allowedPermissions = permissionsArray && permissionsArray.length !== 0 ? permissionsArray.map(permission => permission?.permission) : [];

// const permissionsAccessFunction = (brand) => {
//   return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(brand));
// }
// ==============================|| MENU ITEMS - permissions ||============================== //

let permissionUser = localStorage.getItem("logindata");
let decryptedData = null;
if (permissionUser) {
  decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
    enc.Utf8
  );
}

const permisions = JSON.parse(decryptedData);
const allowedPermissions =
  permisions && permisions.length !== 0
    ? permisions.map((permission) => permission?.permission)
    : [];
const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};

const userManagment = permissionsAccessFunction("users")
  ? {
      id: "utilities",
      // title: 'User Management',
      icon: icons.navigation,
      type: "group",
      children: [
        allowedPermissions.includes("users.getAll")
          ? {
              id: "all-users",
              title: "Users",
              type: "item",
              url: "/userManagment/all-users",
              icon: icons.shadow,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;

export default userManagment;
