// iconsHelper.js
import { MdOutlineDashboard } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";
import { LuUsers } from "react-icons/lu";
import { FaMap } from "react-icons/fa";
import { CiLocationArrow1 } from "react-icons/ci";

const iconsHelper = {
  dashboard: MdOutlineDashboard,
  customer: RiCustomerService2Fill,
  users: LuUsers,
  mapicon: FaMap,
  location: CiLocationArrow1,
};

export default iconsHelper;
