import {GET_ALL_CURRENCY} from "../../constant/ActionType";

export default function CurrencyyAllReducer(state = {
    currencyallData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case GET_ALL_CURRENCY:
			return { ...state,
				currencyallData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
