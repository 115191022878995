// type
import { Home3, Tag2, Colorfilter, Barcode } from "iconsax-react";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { AES, enc } from "crypto-js";
import * as url from "../store/constant/Endpoints";
// icons
const icons = {
  navigation: Home3,
  typography: Tag2,
  color: Colorfilter,
  shadow: Barcode,
  voucher: ReceiptLongIcon,
};

// let permissionUser = localStorage.getItem("LoginData");
// let permissions = JSON.parse(permissionUser)?.permissions;

// const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map(permission => permission?.permission) : [];

// const permissionsAccessFunction = (batch) => {
//   return allowedPermissions && allowedPermissions.length !== 0 && allowedPermissions.some(permission => permission.includes(batch));
// }

let permissionUser = localStorage.getItem("logindata");
let decryptedData = null;
if (permissionUser) {
  decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
    enc.Utf8
  );
}

const permissions = JSON.parse(decryptedData);
const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];
const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};

//===============================|| MENU ITEMS - batch ||============================== //

const paymentVoucher = permissionsAccessFunction("payment")
  ? {
      id: "utilities",
      // title: 'Voucher',
      icon: icons.Tag2,
      type: "group",
      children: [
        allowedPermissions.includes("payment.getAll")
          ? {
              id: "payment-voucher",
              title: "Voucher",
              type: "item",
              url: "/domain-parameter/payment-voucher",
              icon: icons.voucher,
              breadcrumbs: true,
            }
          : null,
      ].filter(Boolean),
    }
  : null;

export default paymentVoucher;
