import {VIEW_QUOTATION} from "../../constant/ActionType";

export default function quotationViewAllReducer(state = {
    viewQuotation:[],
    statuscode: null,
}, action) {
    switch (action.type) {    
        case VIEW_QUOTATION:
			return { ...state,
				viewQuotation: action.data ,
                statuscode:action.status
            };

        default:
    }
    return state;
}
