import React, { useState } from 'react';
import { Select } from 'antd';

const SearchTextSelect = ({handleChangeValue ,options,value}) => {
  const [customer_id, setCustomerId] = useState('');
  const [searchedText, setSearchedText] = useState('');
  const handleChangee = (value, option) => {
    if (option && option.key === '__SEARCH__') {
      setCustomerId(searchedText);
      handleChangeValue(searchedText)
    } else {
      setCustomerId(value);
      handleChangeValue(value);
    }
  };

  const handleSearch = (value) => {
    setSearchedText(value);
  };

  return (
    <Select
      showSearch
      style={{
        width: '100%',
        height: '54px',
        marginTop: '8px',
      }}
      value={value}
      onSelect={handleChangee}
      onSearch={handleSearch}
      tokenSeparators={[',']}
      options={options.concat([{ label: searchedText, key: '__SEARCH__',value:searchedText }])}
      placeholder="Select Customer"
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
    />
  );
};

export default SearchTextSelect;
