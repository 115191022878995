import React, { useState } from 'react';
import {
  Button,
  Grid,
  OutlinedInput,
  Stack,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// import { ForgetPassword } from 'store/action/index';
// import { dispatch } from 'store/index';

const AuthForget = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
  };

  const handleSubmit = async () => {
    try {
      setIsValidEmail(validateEmail(email));
      if (isValidEmail) {
        setLoading(true);
        // dispatch(ForgetPassword({
        //     email: email,
        //     setLoading:setLoading
        // }))
      }

    } catch (err) {
      console.error(err);
      setLoading(false); // Stop loading in case of an error
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <OutlinedInput
              onChange={handleEmailChange}
              id="email-login"
              type="email"
              name="email"
              placeholder="Enter email address"
              fullWidth
              required
            />
            {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginTop: 10 }}>
            <Button
              onClick={handleSubmit}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Forget Password'}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
export default AuthForget;
