import {ALL_EMPLOYEE_ALLOWANCE} from "../../constant/ActionType";

export default function EmployeeAllowanceReducer(state = {
    EmpAllowance:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case ALL_EMPLOYEE_ALLOWANCE:
			return { ...state,
				EmpAllowance: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}








