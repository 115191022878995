import { TreeView } from "@mui/x-tree-view/TreeView";
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import AdjustIcon from "@mui/icons-material/Adjust";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MapIcon from "@mui/icons-material/Map";
import LocationIcon from "@mui/icons-material/LocationOn";
import { MdOutlineDashboard } from "react-icons/md";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { IoIosInformationCircle } from "react-icons/io";
import StoreIcon from "@mui/icons-material/Store";
import { LiaMoneyCheckSolid } from "react-icons/lia";
import { MdOutlinePolicy } from "react-icons/md";
import { GrMoney } from "react-icons/gr";
import { AiOutlineDollar } from "react-icons/ai";
import { PiBuildingOffice } from "react-icons/pi";
import PeopleSharpIcon from "@mui/icons-material/PeopleSharp";
import AccountBalanceSharpIcon from "@mui/icons-material/AccountBalanceSharp";
import AccountBalanceWalletSharpIcon from "@mui/icons-material/AccountBalanceWalletSharp";
import { IoIosCreate } from "react-icons/io";
import { GrTemplate } from "react-icons/gr";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DnsIcon from "@mui/icons-material/Dns";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import BarChartIcon from "@mui/icons-material/BarChart";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import FolderIcon from "@mui/icons-material/Folder";
import { FaUserTag } from "react-icons/fa";
import PaymentsIcon from "@mui/icons-material/Payments";
import { IoMdFingerPrint } from "react-icons/io";
import { FaMinus } from "react-icons/fa6";
import { AiTwotoneDollar } from "react-icons/ai";
import { FcLeave } from "react-icons/fc";
import { FaUsersLine } from "react-icons/fa6";
import { VscChecklist } from "react-icons/vsc";
import { ShopRemove } from "iconsax-react";
import { AES, enc } from "crypto-js";
import * as url from "../../../../../store/constant/Endpoints";
import {
  TreeItem,
  TreeItemProps,
  treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import KeySharpIcon from "@mui/icons-material/KeySharp";
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
    borderTopLeftRadius: theme.spacing(1),
    borderBottomLeftRadius: theme.spacing(1),
    paddingRight: theme.spacing(0),
    marginLeft: `${theme.spacing(1.5)} !important`,
    // marginRight: `${theme.spacing(1)} !important`, // Add !important to force the margin
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&": {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.3),
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(0),
    },
  },
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
  const theme = useTheme();
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    colorForDarkMode,
    bgColorForDarkMode,
    ...other
  } = props;

  const styleProps = {
    "--tree-view-color":
      theme.palette.mode !== "dark" ? color : colorForDarkMode,
    "--tree-view-bg-color":
      theme.palette.mode !== "dark" ? bgColor : bgColorForDarkMode,
  };

  return (
    <StyledTreeItemRoot
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            pl: 0,
            color: "#E83138",
          }}
        >
          <Box
            component={LabelIcon}
            color="inherit"
            sx={{ mr: 1, fontSize: "22px !important" }}
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1, fontSize: "14px" }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={styleProps}
      {...other}
      ref={ref}
    />
  );
});

let assignUserChecking = localStorage.getItem("userassigncompany");

// ...(dashboard && assignUserChecking == "false" ? [dashboard] : []),

const TreeNav = () => {
  let permissionUser = localStorage.getItem("logindata");
  let decryptedData = null;
  if (permissionUser) {
    decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
      enc.Utf8
    );
  }

  const permissions = JSON.parse(decryptedData);
  const allowedPermissions =
    permissions && permissions.length !== 0
      ? permissions.map((permission) => permission?.permission)
      : [];
  const permissionsAccessFunction = (brand) => {
    return (
      allowedPermissions &&
      allowedPermissions.length !== 0 &&
      allowedPermissions.some((permission) => permission.includes(brand))
    );
  };

  return (
    <TreeView
      aria-label="gmail"
      defaultExpanded={[]}
      defaultSelected={""}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      style={{
        //  height: 264,
        color: "#E03F45",
        flexGrow: 1,
        maxWidth: 400,
      }}
      selected={""}
      onNodeSelect={(e) => {
        console.log("ddd", e.target);
      }}
    >
      {permissionsAccessFunction("company_getAll") ||
      permissionsAccessFunction("customer_getAll") ||
      permissionsAccessFunction("tax_getAll") ||
      permissionsAccessFunction("terms_and_condition_getAll") ||
      permissionsAccessFunction("account_getAll") ||
      permissionsAccessFunction("templetes_getAll") ||
      permissionsAccessFunction("recurring_emails_getAll") ||
      permissionsAccessFunction("designation_getAll") ||
      permissionsAccessFunction("branch_getAll") ||
      permissionsAccessFunction("deduction_getAll") ||
      permissionsAccessFunction("leaveType_getAll") ||
      permissionsAccessFunction("companyPolicy_getAll") ||
      permissionsAccessFunction("allowance_getAll") ||
      permissionsAccessFunction("currency_getAll") ? (
        <StyledTreeItem
          nodeId="/all-center-point"
          labelText="Domain Parameters"
          labelIcon={AdjustIcon}
        >
          {allowedPermissions.includes("domainParameter.company.viewAll") ? (
            <Link
              to={"/domain-parameter/all-company"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-company"
                labelText="Company"
                labelIcon={StoreIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.customer.viewAll") &&
          assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/all-customer"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-customer"
                labelText="Customer"
                labelIcon={PeopleSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.tax.viewAll") &&
          assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/all-taxes"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-taxes"
                labelText="Taxes"
                labelIcon={AccountBalanceSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.account.viewAll") &&
          assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/chart-of-account"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="chart-of-account"
                labelText="Chart of Account"
                labelIcon={AccountBalanceWalletSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes(
            "domainParameter.terms_and_condition.viewAll"
          ) && assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/terms-and-conditions"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="terms-and-conditions"
                labelText="Terms & Conditions"
                labelIcon={LocationIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.templates.viewAll") &&
          assignUserChecking == "false" ? (
            <Link
              to={"/domain-parameter/all-templates"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="templates"
                labelText="Templates"
                labelIcon={GrTemplate}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes(
            "domainParameter.recurringEmails.viewAll"
          ) ? (
            <Link
              to={"/recuring/all-recuring-email"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-recuring-email"
                labelText="Recuring Email"
                labelIcon={EmailSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes(
            "domainParameter.designation.viewAll"
          ) ? (
            <Link
              to={"/domain-parameter/designation"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="designation"
                labelText="Add Designation"
                labelIcon={ControlCameraIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.branch.viewAll") ? (
            <Link
              to={"/domain-parameter/branch"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="branch"
                labelText="Add Branch"
                labelIcon={DnsIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.leaveType.viewAll") ? (
            <Link
              to={"/domain-parameter/leave-type"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="leave-type"
                labelText="Add Leave Type"
                labelIcon={ExitToAppIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes(
            "domainParameter.companyPolicy.viewAll"
          ) ? (
            <Link
              to={"/domain-parameter/company-policy-create"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="company-policy"
                labelText="Company Policy"
                labelIcon={MdOutlinePolicy}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("domainParameter.allowance.viewAll") ? (
            <Link
              to={"/domain-parameter/payroll-allowance"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="payroll-allowance"
                labelText="Allowance"
                labelIcon={GrMoney}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("domainParameter.deduction.viewAll") ? (
            <Link
              to={"/domain-parameter/payroll-deduction"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="payroll-deduction"
                labelText="Deduction"
                labelIcon={FaMinus}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
          {allowedPermissions.includes("domainParameter.currency.viewAll") ? (
            <Link
              to={"/domain-parameter/currency"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="currency"
                labelText="Currency"
                labelIcon={AiOutlineDollar }
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}

      {permissionsAccessFunction("allview_reports") ||
      permissionsAccessFunction("view_report_ledger") ? (
        <StyledTreeItem
          nodeId="/all-center-pointssss"
          labelText="Reports"
          labelIcon={MultilineChartIcon}
        >
          {allowedPermissions.includes("allview_reports") ? (
            <Link
              to={"/reports/all-reports"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-reports"
                labelText="Reports"
                labelIcon={BarChartIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("view_report_ledger") ? (
            <Link to={"/reports/ledger"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="company_ledger"
                labelText="Ledger"
                labelIcon={FolderIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}

      {permissionsAccessFunction("users") ? (
        <StyledTreeItem
          nodeId="/all-center-pointwwwssss"
          labelText="User Management"
          labelIcon={AccountCircleSharpIcon}
        >
          {allowedPermissions.includes("users.getAll") ? (
            <Link
              to={"/userManagment/all-users"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-users"
                labelText="Users"
                labelIcon={AccountCircleSharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("assign-permission") ? (
            <Link to={"/permissions/assign"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="assign"
                labelText="Assign Permission"
                labelIcon={KeySharpIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("get-role") ? (
            <Link to={"/roles/all-roles"} style={{ textDecoration: "none" }}>
              <StyledTreeItem
                nodeId="allroles"
                labelText="Roles"
                labelIcon={FaUserTag}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}

      {permissionsAccessFunction("employee_info_getAll") ? (
        <StyledTreeItem
          nodeId="/hrm-employee"
          labelText="Employee Information"
          labelIcon={FaUsersLine}
        >
          {allowedPermissions.includes("employee_info.viewAll") ? (
            <Link
              to={"/hrm-modules/employee-info"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-employee"
                labelText="Add Employee Information"
                labelIcon={ContentPasteIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}
      {permissionsAccessFunction("leave_management_getAll") ? (
        <StyledTreeItem
          nodeId="/leave-management"
          labelText="Leave Management"
          labelIcon={FcLeave}
        >
          {allowedPermissions.includes("leave_management.viewAll") ? (
            <Link
              to="/leave-management/all-leaves"
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-customer"
                labelText="Create Leave"
                labelIcon={IoIosCreate }
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}

      {/* {permissionsAccessFunction("loan_management_getAll") ? (
        <StyledTreeItem
          nodeId="/loan-management"
          labelText="Loans"
          labelIcon={AccountCircleSharpIcon}
        >
          {allowedPermissions.includes("loan_management.viewAll") ? (
            <Link
              to="/loan-management/all-loans"
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-loans"
                labelText="Add Loans"
                labelIcon={IoIosInformationCircle}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null} */}

      {permissionsAccessFunction("payroll_management_getAll") ? (
        <StyledTreeItem
          nodeId="/payroll-management"
          labelText="Payroll"
          labelIcon={FaMoneyBill1Wave}
        >
          {allowedPermissions.includes("generate_salary.viewAll") ? (
            <Link
              to={"/hrm-modules/payroll-management"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-payroll"
                labelText="Generate Salary"
                labelIcon={PaymentsIcon}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}

          {allowedPermissions.includes("payslip.viewAll") ? (
            <Link
              to={"/hrm-modules/payroll-payslip"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-payroll-payslip"
                labelText="PaySlip"
                labelIcon={LiaMoneyCheckSolid }
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}

      {permissionsAccessFunction("attendance_management_getAll") ? (
        <StyledTreeItem
          nodeId="/attendance-management"
          labelText="Attendance"
          labelIcon={VscChecklist}
        >
          {allowedPermissions.includes("attendance.viewAll") ? (
            <Link
              to={"/attendance-management/all-attendance"}
              style={{ textDecoration: "none" }}
            >
              <StyledTreeItem
                nodeId="all-attendance"
                labelText="Biometric Attendance"
                labelIcon={IoMdFingerPrint}
                color="red"
                bgColor="#FCE4E4"
                colorForDarkMode="#E03F45"
                bgColorForDarkMode="#E03F45"
              />
            </Link>
          ) : null}
        </StyledTreeItem>
      ) : null}
    </TreeView>
  );
};

export default TreeNav;
