import { ALLCREATEUSERS } from "../../constant/ActionType";

export default function UsersCreate(state = {
    create: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case ALLCREATEUSERS:
            return {
                ...state,
                create: action.data?.data,
                statusCode: action.status
            };

        default:
            console.log("actionsss:",action)
            return state;
    }
}
