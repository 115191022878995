import {FETCH_CURRENCY} from "../../constant/ActionType";

export default function CurrencyReducer(state = {
    currencyData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case FETCH_CURRENCY:
			return { ...state,
				currencyData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
