import { QUOTATIONS_REPORTS } from "../../constant/ActionType";

export default function dashboardallquotationsreports(state = {
    quotationsreportsget: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case QUOTATIONS_REPORTS:
            return {
                ...state,
                quotationsreportsget: action.data,
                statuscode: action.status
            };

        default:
            console.log("checkingg", action)
    }
    return state;
}
