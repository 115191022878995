import {ALL_LOAN_TYPE} from "../../constant/ActionType";

export default function LoansTypesReducer(state = {
    loanTypeData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case ALL_LOAN_TYPE:
			return { ...state,
				loanTypeData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}



