// material-ui

import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { dispatch } from "store/index";
import { fetchTemplateSend, fetchEditttTemplatee } from "store/action/index";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Input } from "antd";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const CreateTemplate = () => {
  let location = useLocation();

  const [template_name, setTemplate_name] = useState(
    location.state !== null ? location.state?.templete_name : ""
  );
  const [template_code, setTemplate_code] = useState(
    location.state !== null ? location.state?.templete_code : ""
  );
  const [template_code2, setTemplate_code2] = useState(
    location.state !== null ? location.state?.templete_code : ""
  );

  const { TextArea } = Input;

  const [template_type, setTemplate_type] = useState(
    location.state !== null ? location.state?.templete_type : ""
  );

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (template_name !== "" && template_code !== "" && template_type !== "") {
      setLoading(true);

      if (location.state !== null) {
        dispatch(
          fetchEditttTemplatee({
            id: location.state.id,
            templete_name: template_name,
            templete_code: template_code,
            templete_code2: template_code2,
            templete_type: template_type,
            company_id: localStorage.getItem("companyName"),
            setLoading: setLoading,
          })
        );
      } else {
        dispatch(
          fetchTemplateSend({
            templete_name: template_name,
            templete_code: template_code,
            templete_code2: template_code2,
            templete_type: template_type,
            company_id: localStorage.getItem("companyName"),
            setLoading: setLoading,
          })
        );
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };

  return (
    <MainCard>
      <Spin spinning={loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="row">
              <div className="col-md-6 my-2">
                <ThemeInput
                  id="taxName"
                  value={template_name}
                  onChange={(e) => setTemplate_name(e.target.value)}
                  type="text"
                  name="Template Name*"
                  placeholder="Enter Template Name"
                />
              </div>

              <div className="col-md-6 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="company">Template Type*</InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        value={template_type}
                        onChange={(e) => setTemplate_type(e.target.value)}
                        id="company"
                        name="roleid"
                        displayEmpty
                        input={<OutlinedInput />}
                      >
                        <MenuItem disabled value="">
                          <em>Select Template Type</em>
                        </MenuItem>
                        <MenuItem value={1}>Invoice Template</MenuItem>
                        <MenuItem value={2}>Quotation Template</MenuItem>
                        <MenuItem value={3}>Delivery Note Template</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </div>

              <div className="col-md-6 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="company">
                      Template With Watermark*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth >
                      <TextArea
                        rows={8} 
                        placeholder="Enter Template Code"
                        name="Template Code*"
                        value={template_code}
                        allowClear
                        onChange={(e) => setTemplate_code(e.target.value)}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </div>
              <div className="col-md-6 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="company">
                      Template Without Watermark*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <TextArea
                        rows={8} 
                        placeholder="Enter Template Code"
                        name="Template Code*"
                        value={template_code2}
                        allowClear
                        onChange={(e) => setTemplate_code2(e.target.value)}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </div>
            </div>
            <div
              className="row my-5"
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 5,
              }}
            >
              <Button
                style={{ width: "auto" }}
                className="px-3"
                onClick={handleSubmit}
                disableElevation
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {location.state !== null ? "Edit Template" : "Save Template"}
              </Button>
            </div>{" "}
          </Grid>
        </Grid>
      </Spin>
    </MainCard>

    //  </Grid>
  );
};

export default CreateTemplate;
