// material-ui

import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useState } from "react";
import { Grid, InputLabel, Select, MenuItem, FormControl, Stack, OutlinedInput, Button } from "@mui/material";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { dispatch } from "store/index";
import { fetchTermsCondtiosnsn, fetchtemrsCotitonsEdit } from "store/action/index";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import ReactQuill from 'react-quill';

const CreateTermsAndCondition = () => {
  let location = useLocation();
  const [loading, setLoading] = useState(false);
  const [TermDecccc, SetTermCondiDescri] = useState(location.state !== null ? location.state?.term_description : "");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [userObj, setUserObj] = useState({});

  useEffect(() => {
    // const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    // const htmlString = html.toString();
    // const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    // const text = htmlString;
    // const text = doc.body.textContent || "";
    setUserObj({ ...userObj, term_description: TermDecccc })
  }, [TermDecccc])
  useEffect(() => {
    console.log("terssmsss", location.state)
  }, [location])


  const handleSubmit = () => {

    // const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    // const htmlString = html.toString();
    // let term_description = htmlString;
    if (userObj.term_name !== undefined && userObj.term_description !== undefined && userObj.term_type !== undefined) {
      setLoading(true);
      if (location.state !== null) {
        dispatch(fetchtemrsCotitonsEdit({ userObj, setLoading: setLoading }));
      } else {
        dispatch(fetchTermsCondtiosnsn({ userObj, setLoading: setLoading }));
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };

  useEffect(() => {
    if (location?.state?.term_name) {
      let EditDataObject = {
        id: location?.state?.id,
        term_name: location?.state?.term_name,
        term_description: location?.state?.term_description,
        term_type: location?.state?.term_type,
      };
      setUserObj(EditDataObject);
    }
  }, [location?.state]);

  return (
    <MainCard>
      <Spin spinning={false}>
        <Grid container spacing={2} sx={{ marginTop: "" }}>
          <Grid item xs={12}>
            <div className="row">
              <div className="col-md-6 my-2">
                <ThemeInput
                  type="text"
                  name="Term Name*"
                  value={userObj.term_name || ""}
                  placeholder="Term Name"
                  onChange={(e) => setUserObj({ ...userObj, term_name: e.target.value })}
                />
              </div>
              <div className="col-md-6 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Term Type*
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      style={{ height: "52px" }}
                      value={userObj.term_type || ""}
                      onChange={(e) => setUserObj({ ...userObj, term_type: e.target.value })}
                    >
                      <MenuItem disabled value="">
                        <em>Select Term Type</em>
                      </MenuItem>

                      <MenuItem value={1}>Invcoice Terms</MenuItem>
                      <MenuItem value={2}>Quotation Terms</MenuItem>
                      <MenuItem value={3}>Payment Terms</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </div>

              <div className="col-md-12 my-2">
                {/* <ThemeInputen
                  type="text"
                  name="Term Description*"
                  placeholder="Term Description"
                  value={userObj.term_description || ""}
                  onChange={(e) => setUserObj({ ...userObj, term_description: e.target.value })}
                /> */}
                <label className="mr-3">Description / "Terms & Conditions"</label>
                <div style={{ height: "200px", overflowY: "scroll" }}>
                  {/* <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={setEditorState}
                  /> */}
                  <ReactQuill theme="snow" value={TermDecccc} onChange={SetTermCondiDescri} />

                </div>
              </div>
            </div>
            <div
              className="row my-5"
              style={{ textAlign: "end", display: "flex", justifyContent: "flex-end", marginRight: 5 }}
            >
              <Button
                style={{ width: "auto" }}
                className="px-3"
                onClick={handleSubmit}
                disableElevation
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : location.state !== null ? (
                  "Edit Terms And Condition"
                ) : (
                  "Save Terms And Condition"
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Spin>
    </MainCard>
  );
};

export default CreateTermsAndCondition;
