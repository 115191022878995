import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import MainCard from "components/MainCard";
import { ArrowRight2 } from "iconsax-react";

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({ navigation, title, sx, ...others }) => {
  const [item, setItem] = useState();
  const { id } = useParams();
  const location = useLocation();
  const currentRoute = location.pathname;

  const pathParts = currentRoute.split("/");

  // Get the required part (excluding the ID part)
  const requiredPath = `/${pathParts[1]}/${pathParts[2]}`;

  useEffect(() => {
    console.log("CurrentRoute:", requiredPath);
  }, [requiredPath]);

  useEffect(() => {
    navigation?.items?.map((menu) => {
      if (menu.type && menu.type === "group") {
        getCollapse(menu);
      }
      return false;
    });
  });

  let customLocation = location.pathname;

  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse && collapse.type && collapse.type === "collapse") {
          getCollapse(collapse);
          if (collapse && collapse.url === customLocation) {
            setItem(collapse);
          }
        } else if (collapse && collapse.type && collapse.type === "item") {
          if (customLocation === collapse.url) {
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  let mainContent;
  let itemContent;
  let breadcrumbContent = <Typography />;
  let itemTitle = "";

  // items
  if (item && item.type === "item") {
    itemTitle = item.title;

    itemContent = (
      <Typography
        variant="h6"
        color="secondary"
        sx={{ display: "flex", alignItems: "center" }}
      >
        {itemTitle}
      </Typography>
    );

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          sx={{ mb: 3, bgcolor: "transparent", border: "none", ...sx }}
          {...others}
          content={false}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={0.5}
          >
            <Grid item sx={{ marginBottom: "40px" }}>
              {/* <MuiBreadcrumbs
                aria-label="breadcrumb"
                separator={<ArrowRight2 size={12} />}
              >
                <Typography
                  onClick={() => (window.location.href = "/")}
                  color="textPrimary"
                  variant="h6"
                  sx={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  Home
                </Typography>
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs> */}
            </Grid>
            {title && (
              <Grid item sx={{ mt: 0 }}>
                {/* <Typography variant="h2" sx={{ fontWeight: 700 }}>
                  {requiredPath == "/invoice/create-invoice"
                    ? "All Invoice"
                    : item.title}
                </Typography> */}
              </Grid>
            )}
          </Grid>
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  navigation: PropTypes.object,
  title: PropTypes.bool,
  sx: PropTypes.object,
  items: PropTypes.object,
  children: PropTypes.node,
};

export default Breadcrumbs;
