import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Select as SelectAnt } from "antd";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { fetchCompanyDataa, fetchDeleteCompany } from "../store/action/index";
import { connect } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  // bgcolor: 'background.paper',
  backgroundColor: "white",
  // border: '2px solid #white',
  boxShadow: 24,
  maxHeight: 500,
  overflowY: "hidden",
  overflowX: "hidden",
  border: "none",
  p: 2,
};

const Modals = ({ open, handleClose, allcompany }) => {
  const [Filter_data, set_Filter_Data] = useState();
  let loginDetailsLocalStorage = localStorage.getItem("userData");
  let loginDetails = JSON.parse(loginDetailsLocalStorage);
  console.log("logigigigig", loginDetails);
  useEffect(() => {
    if (loginDetails) {
      let FilterData = loginDetails?.company?.filter(
        (item) => item.company_type === "own"
      );
      set_Filter_Data(FilterData);
    }
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  const CurrencyChangeHandle = (value) => {
    localStorage.setItem("companyName", value);
    window.location.reload();
  };

  const handleModalClose = () => {
    // You can add additional logic here if needed
    // handleClose(); // Uncomment this line if you want to close the modal on outside click
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleModalClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <h4 style={{ textAlign: "center", marginTop: "20px" }}>
          Please Select a Company
        </h4>
        <div style={{}}>
          <Stack>
            <FormControl variant="outlined" fullWidth></FormControl>
          </Stack>

          <Select
            // value={company_id}
            onChange={(e) => CurrencyChangeHandle(e.target.value)}
            sx={{
              width: "240px",
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            {Filter_data &&
              Filter_data.length !== 0 &&
              Filter_data?.map((v, e) => {
                return <MenuItem value={v.id}>{v.company_name}</MenuItem>;
              })}
          </Select>
        </div>
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    allcompany: state.company.companyData?.data,
  };
};

export default connect(mapStateToProps)(Modals);
