import {QUOTATIONS_ALL_EXPORT} from "../../constant/ActionType";

export default function quotationExportReducer(state = {
    exportQuotation:[],
    statuscode: null,
}, action) {
    switch (action.type) {    
        case QUOTATIONS_ALL_EXPORT:
			return { ...state,
				exportQuotation: action.data ,
                statuscode:action.status
            };

        default:
    }
    return state;
}
