import { DESIGNATIONSS_GET_DATA_VIEW } from "../../constant/ActionType";

export default function DesginationnsRedcuerssFle(
  state = {
    desginationdata: [],
    statuscode: null,
  },
  action
) {
  switch (action.type) {
    case DESIGNATIONSS_GET_DATA_VIEW:
      return {
        ...state,
        desginationdata: action.data,
        statuscode: action.status,
      };

    default:
  }

  return state;
}
