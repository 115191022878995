import {CREATE_ALL_ATTENDANCE} from "../../constant/ActionType";

export default function AttendanceCrcReducer(state = {
    attendanceCrcData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case CREATE_ALL_ATTENDANCE:
			return { ...state,
				attendanceCrcData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}




