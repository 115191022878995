
import { ALL_GET_REPORTS } from "../../constant/ActionType";


export default function AllREportGetRender(state = {
    allReportdata: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_GET_REPORTS:
            return {
                ...state,
                allReportdata: action.data,
                statuscode: action.status
            };

        default:
    }

    return state;
}