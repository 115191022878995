import {NUMBER_FIELD} from "../constant/ActionType";

export default function NumberFieldCusrReducer(state = {
    numberfield:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case NUMBER_FIELD:
			return { ...state,
				numberfield: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
