import {
    TEMPLATE_GET
} from "../../constant/ActionType";

export default function TemplateGettingResdunerr(state = {
    templateget: [],
    statusCode: null,
}, action) {
    switch (action.type) {
        case TEMPLATE_GET:
            return {
                ...state,
                templateget: action.data,
                    statusCode: action.status
            };

        default:
    }
    return state;
}