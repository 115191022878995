import { CREATE_SALARY_FETCH } from "../constant/ActionType";

export default function SalaryCrReducer(
  state = {
    createsalrget: [],
  },
  action
) {
  switch (action.type) {
    case CREATE_SALARY_FETCH:
      return { ...state, createsalrget: action.data };

    default:
  }
  return state;
}
