import {FETCH_DASHBOARD} from "../../constant/ActionType";

export default function dashboardtAllReducer(state = {
    dashboardget:[],
    statuscode: null,
}, action) {
    switch (action.type) {    
        case FETCH_DASHBOARD:
			return { ...state,
				dashboardget: action.data ,
                statuscode:action.status
            };

        default:
    }
    return state;
}
