import MainCard from "components/MainCard";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { downlaodpdfurl } from "store/constant/Endpoints";
import AddCompanyModal from "components/modals/addcompnaymodal";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { UploadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import ReactQuill from "react-quill";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin, Table, Space, Input, Modal, Upload } from "antd";
import { dispatch } from "store/index";
import {
  fetchQuoatationGetAll,
  fetchAllVwwTrmsoitins,
  fetchNumberField,
  fetchCustomer,
  fetchCompanyDataa,
  fetchStoreQuotation,
  fetchTaxesComp,
  fetchCurrency,
  fetchViewQuotation,
  updateStoreQuotation,
  fetchAllTrmActive,
} from "store/action/index";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Select as SelectAnt } from "antd";
import draftToHtml from "draftjs-to-html";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SearchTextSelect from "components/selects/SearchTextSelect";
import { log } from "../../../node_modules/util/util";
import AddIcon from "@mui/icons-material/Add";

// ==============================|| Create / Edit Company - SICPA ||============================== //

const CreateQuotation = ({
  quotationsssactive,
  customerData,
  companyData,
  taxes,
  numberfield,
  currencyData,
  viewQuotionData,
}) => {
  const location = useLocation();
  const { TextArea } = Input;
  const [modalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfType, setPdfType] = useState("");
  const [increaseAmount, setIncreaseAmount] = useState(0);
  const [uploadedData, setUploadedData] = useState([]);
  const [TermDecccc, SetTermCondiDescri] = useState(
    location.state !== null ? location.state?.terms : ""
  );
  const [is_lock, setIsLock] = useState("1");
  const [note, setNotee] = useState(
    location.state !== null ? location.state?.note : ""
  );
  const [company_id, setCompanyId] = useState(
    location.state !== null ? location.state?.company_id : ""
  );
  const [customer_id, setCustomerId] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [termconditonGet, setTermCOndit] = useState();
  const [loading, setLoading] = useState(false);
  const [currency, setcurrency] = useState(
    location.state !== null ? location.state?.currency : ""
  );
  const [Modalcompany, setModalCompany] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [AdditionalPrice, setAdditionalPrice] = useState(
    location.state !== null ? location.state?.customer_name : ""
  );
  const [payment_term, setpayment_term] = useState(
    location.state !== null ? location.state?.payment_term : ""
  );
  const [date, setdate] = useState(
    location.state !== null
      ? location.state?.date
      : new Date().toISOString().split("T")[0]
  );
  const [expDate, setExpDate] = useState(
    location.state !== null ? location.state?.valid_till : ""
  );
  const [quotation_type, setquotation_type] = useState(
    location.state !== null ? location.state?.quotation_type : ""
  );
  const [number, setNumber_Field] = useState("");
  const [tableData, setTableData] = useState([
    {
      columnsdata: [
        {
          s_no: "1",
          description: "",
          qty: 0,
          unit: "",
          unit_price: 0,
          total_price: 0,
        },
      ],
      sub_total: "",
      taxes: [{ tax_id: "", tax_value: "", tax_type: "" }],
      total: "",
      table_title: "",
      sub_title: "",
    },
  ]);

  useEffect(() => {
    if (termconditonGet) {
      SetTermCondiDescri(termconditonGet);
    }
  }, [termconditonGet]);

  useEffect(() => {
    dispatch(fetchCustomer());
    dispatch(fetchCompanyDataa());
    dispatch(fetchAllVwwTrmsoitins());
    dispatch(fetchQuoatationGetAll());
    dispatch(fetchTaxesComp());
    dispatch(fetchCurrency());
  }, []);

  const handleImageUpload = async (file, onSuccess, onError) => {
    try {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel"
      ) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          const allSheetsData = workbook.SheetNames.map((sheetName) => {
            const sheet = workbook.Sheets[sheetName];
            let excelData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            if (excelData.length > 0) {
              excelData = excelData.slice(1); // Skip header
            }

            const sheetData = excelData.map((row, index) => ({
              s_no: (index + 1).toString(),
              description: row[0] || "",
              qty: row[1] || 0,
              unit_price: row[2] || 0,
              total_price: row[1] * row[2] || 0,
            }));

            return {
              sheetName,
              columnsdata: sheetData,
              sub_total: sheetData
                ?.reduce((acc, row) => acc + row.total_price, 0)
                .toFixed(2),
              taxes: [{ tax_id: "", tax_value: "", tax_type: "" }],
              services: [{ service_name: "", service_amount: "" }],
              total: "",
              table_title: sheetName,
            };
          });

          // Temporarily store the data until the user submits
          setUploadedData(allSheetsData);
          onSuccess();
        };
        reader.readAsArrayBuffer(file);
      } else {
        throw new Error("Invalid file type. Only Excel files are allowed.");
      }
    } catch (error) {
      onError(error);
      console.error("Upload failed:", error.message);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleFieldChange = (tableIndex, fieldIndex, field, value) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].columnsdata)
    ) {
      const updatedFields = [...updatedTableData[tableIndex].columnsdata];

      if (updatedFields[fieldIndex] !== undefined) {
        // Update the field value
        updatedFields[fieldIndex][field] = value;

        // Calculate total price for the current row
        const qty = parseFloat(updatedFields[fieldIndex].qty) || 0;
        const unitPrice = parseFloat(updatedFields[fieldIndex].unit_price) || 0;
        updatedFields[fieldIndex].total_price = (qty * unitPrice).toFixed(2);

        // Update columnsdata
        updatedTableData[tableIndex].columnsdata = updatedFields;

        // Calculate sub_total
        const subTotal = updatedFields
          ?.reduce((acc, row) => acc + parseFloat(row.total_price || 0), 0)
          .toFixed(2);
        updatedTableData[tableIndex].sub_total = subTotal;

        // Calculate Total
        let total = parseFloat(subTotal);

        // Apply tax calculations if taxes are selected
        const taxes = updatedTableData[tableIndex].taxes;
        if (taxes && taxes.length > 0) {
          taxes.forEach((tax) => {
            const taxValue = parseFloat(tax.tax_value) || 0;

            if (tax.tax_type == "1") {
              // Percentage-based tax
              total += (total * taxValue) / 100;
            } else if (tax.tax_type == "2") {
              // Fixed-value tax
              total += taxValue;
            }
          });
        }

        // Update total
        updatedTableData[tableIndex].total = total.toFixed(2);

        // Update state
        setTableData(updatedTableData);
      } else {
        console.error("Field index out of bounds", { tableIndex, fieldIndex });
      }
    } else {
      console.error(
        "Table index out of bounds or columnsdata is not an array",
        { tableIndex }
      );
    }
  };

  const handleFieldTaxChange = (tableIndex, fieldIndex, field, value) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].taxes)
    ) {
      const updatedFields = [...updatedTableData[tableIndex].taxes];

      if (updatedFields[fieldIndex] !== undefined) {
        updatedFields[fieldIndex][field] = value;
        updatedTableData[tableIndex].taxes = updatedFields;

        // Recalculate total
        const subTotal =
          parseFloat(updatedTableData[tableIndex].sub_total) || 0;
        let total = subTotal;

        updatedFields.forEach((tax) => {
          const taxValue = parseFloat(tax.tax_value) || 0;
          const taxType = parseInt(tax.tax_type, 10);

          if (taxType == 1) {
            // Tax type 1: percentage
            total += (subTotal * taxValue) / 100;
          } else if (taxType == 2) {
            // Tax type 2: flat amount
            total += taxValue;
          }
        });

        updatedTableData[tableIndex].total = total.toFixed(2);
        setTableData(updatedTableData);
      } else {
        console.error("Field index out of bounds", { tableIndex, fieldIndex });
      }
    } else {
      console.error("Table index out of bounds or taxes is not an array", {
        tableIndex,
      });
    }
  };

  const handleCompanyChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, company: value } : item
    );
    setData(updatedData);
    console.log(updatedData, "Ssdsd");
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setModalCompany(newRow.company);
    }
  };

  const handleAdditionalPriceChange = (key, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, additionalPrice: value } : item
    );
    setData(updatedData);
    const newRow = updatedData.find((item) => item.key === key);
    if (newRow) {
      setAdditionalPrice(newRow.additionalPrice);
    }
  };
  const handleDeleteRowTable = (tableIndex, fieldIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].columnsdata)
    ) {
      const updatedFields = [...updatedTableData[tableIndex].columnsdata];

      if (updatedFields.length > 1) {
        // Remove the row
        updatedFields.splice(fieldIndex, 1);
        updatedTableData[tableIndex].columnsdata = updatedFields;

        // Recalculate sub_total after deleting row
        const subTotal = updatedFields
          ?.reduce((acc, row) => acc + parseFloat(row.total_price || 0), 0)
          .toFixed(2);
        updatedTableData[tableIndex].sub_total = subTotal;

        // Recalculate total
        let total = parseFloat(subTotal);

        // Apply tax calculations if taxes are selected
        const taxes = updatedTableData[tableIndex].taxes;
        if (taxes && taxes.length > 0) {
          taxes.forEach((tax) => {
            const taxValue = parseFloat(tax.tax_value) || 0;

            if (tax.tax_type == "1") {
              // Percentage-based tax
              total += (total * taxValue) / 100;
            } else if (tax.tax_type == "2") {
              // Fixed-value tax
              total += taxValue;
            }
          });
        }

        // Update the total field
        updatedTableData[tableIndex].total = total.toFixed(2);

        // Update state
        setTableData(updatedTableData);
      } else {
        console.error("Cannot delete the last row", { tableIndex });
      }
    } else {
      console.error(
        "Table index out of bounds or columnsdata is not an array",
        { tableIndex }
      );
    }
  };

  const initialData = [{ key: 0, company: "", additionalPrice: "" }];
  const [data, setData] = useState(initialData);
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(
    data.length > 1
  );

  const options2 =
    customerData && customerData.length > 0
      ? customerData.map((v) => ({
          value: v.company_name,
          label: v.company_name,
        }))
      : [];

  const TermCoditoptions =
    quotationsssactive && quotationsssactive?.data?.length > 0
      ? quotationsssactive?.data?.map((v) => ({
          value: v.term_description,
          label: v.term_name,
        }))
      : [];

  const currencyOption =
    currencyData && currencyData.length > 0
      ? currencyData.map((v) => ({ value: v.cur_name, label: v.cur_name }))
      : [];

  const handleChangee = (value) => {
    setCustomerId(value);
  };

  const HandleTermCondiotonHadle = (value) => {
    setTermCOndit(value);
  };
  const handleChangeee = async (value) => {
    setCompanyId(value);
    await dispatch(fetchNumberField({ id: value }));

    setNumber_Field(numberfield?.data);
  };

  useEffect(() => {
    handleChangeee(localStorage.getItem("companyName"));
  }, []);

  const handleAddRow = (tableIndex, fieldIndex) => {
    const updatedTableData = [...tableData];
    updatedTableData[tableIndex].columnsdata.push({
      s_no: (updatedTableData[tableIndex].columnsdata.length + 1).toString(),
      description: "",
      qty: 0,
      unit: "",
      unit_price: 0,
      total_price: 0,
    });

    const updatedFields = [...updatedTableData[tableIndex].columnsdata];

    if (updatedFields.length > 1) {
      updatedTableData[tableIndex].columnsdata = updatedFields;
      setTableData(updatedTableData);
    } else {
      console.error("Cannot delete the last row", { tableIndex });
    }

    // setTableData(updatedTableData);
  };

  const handleAddTaxRow = (tableIndex) => {
    const updatedTableData = [...tableData];
    updatedTableData[tableIndex].taxes.push({
      s_no: (updatedTableData[tableIndex].taxes.length + 1).toString(),
      tax_id: "",
      tax_value: "",
      tax_type: "",
    });

    const updatedFields = [...updatedTableData[tableIndex].taxes];

    if (updatedFields.length > 1) {
      updatedTableData[tableIndex].taxes = updatedFields;
      setTableData(updatedTableData);
    } else {
      console.error("Cannot delete the last row", { tableIndex });
    }
  };

  const handleDeleteTaxRow = (tableIndex, taxIndex) => {
    const updatedTableData = [...tableData];

    if (
      updatedTableData[tableIndex] &&
      Array.isArray(updatedTableData[tableIndex].taxes)
    ) {
      const updatedTaxes = [...updatedTableData[tableIndex].taxes];

      // Ensure there is at least one row left
      if (updatedTaxes.length > 1) {
        updatedTaxes.splice(taxIndex, 1);
        const subTotal =
          parseFloat(updatedTableData[tableIndex].sub_total) || 0;
        let total = subTotal;

        updatedTaxes.forEach((tax) => {
          const taxValue = parseFloat(tax.tax_value) || 0;
          const taxType = parseInt(tax.tax_type, 10);

          if (taxType == 1) {
            // Tax type 1: percentage
            total += (subTotal * taxValue) / 100;
          } else if (taxType == 2) {
            // Tax type 2: flat amount
            total += taxValue;
          }
        });

        updatedTableData[tableIndex].taxes = updatedTaxes;
        updatedTableData[tableIndex].total = total.toFixed(2);
        setTableData(updatedTableData);
      } else {
        console.error("Cannot delete the last tax row", { tableIndex });
      }
    } else {
      console.error("Table index out of bounds or taxes is not an array", {
        tableIndex,
      });
    }
  };

  const handleDeleteRow = (key) => {
    const updatedData = data.filter((item) => item.key !== key);
    setData(updatedData);
    if (updatedData.length === 1) {
      setIsDeleteButtonEnabled(false); // Disable the delete button
    }
  };

  const getTaxColumns = (tableIndex) => [
    {
      title: "Tax*",
      dataIndex: "taxes",
      key: "taxes",
      fixed: "right",
      render: (text, record, index) => (
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Stack spacing={1}>
            <InputLabel
              htmlFor={`option-${index}`}
              style={{ fontWeight: "bold", marginBottom: "0px" }}
            >
              Tax {index + 1}
            </InputLabel>
            <FormControl variant="outlined" fullWidth>
              <Select
                value={record.id} // Use the id as the value for Select
                onChange={(e) => {
                  const selectedTaxId = e?.target?.value; // Get the selected id

                  // Find the full tax object that matches the selected id
                  const selectedTax = taxes?.find(
                    (tax) => tax.id == selectedTaxId
                  );

                  // Update the fields with the selected tax values
                  handleFieldTaxChange(
                    tableIndex,
                    index,
                    "tax_id",
                    selectedTax?.id
                  );
                  handleFieldTaxChange(
                    tableIndex,
                    index,
                    "tax_value",
                    selectedTax?.tax_value
                  );
                  handleFieldTaxChange(
                    tableIndex,
                    index,
                    "tax_type",
                    selectedTax?.tax_type
                  );
                }}
                id={`option-${index}`}
                name="roleid"
                input={<OutlinedInput />}
                style={{ minWidth: "200px", marginBottom: "15px" }}
              >
                {taxes &&
                  taxes.length !== 0 &&
                  taxes.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                      {" "}
                      {/* Pass only the id */}
                      {v.tax_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>

          <ThemeInput
            type="text"
            name="Amount*"
            value={record.tax_value} // Use the appropriate value from the record
            disabled={true}
          />
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ backgroundColor: "lightblue" }}
            onClick={() => handleAddTaxRow(tableIndex)}
          >
            <PlusOutlined size={25} color={"#fff"} />
          </Button>
          <Button
            style={{ backgroundColor: "red" }}
            onClick={() => handleDeleteTaxRow(tableIndex, index)}
            disabled={tableData[tableIndex].taxes.length <= 1}
          >
            <DeleteOutlined size={25} color={"#fff"} />
          </Button>
        </Space>
      ),
    },
  ];

  const getColumns = (tableIndex) => [
    {
      title: "S.no",
      dataIndex: "s_no",
      width: 100,
      key: "s_no",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Description*",
      dataIndex: "description",
      key: "description",
      fixed: "left",
      render: (text, record, index) => (
        <Input
          value={record.description}
          onChange={(e) =>
            handleFieldChange(tableIndex, index, "description", e.target.value)
          }
        />
      ),
    },
    {
      title: "Quantity*",
      dataIndex: "qty",
      key: "qty",
      render: (text, record, index) => (
        <Input
          type="number"
          value={record.qty}
          onChange={(e) => {
            handleFieldChange(
              tableIndex,
              index,
              "qty",
              Math.max(0, parseFloat(e.target.value))
            );
          }}
        />
      ),
    },
    {
      title: "Unit Price*",
      dataIndex: "unit_price",
      key: "unit_price",
      render: (text, record, index) => (
        <Input
          type="number"
          value={isNaN(record.unit_price) ? 0 : record.unit_price}
          onChange={(e) =>
            handleFieldChange(
              tableIndex,
              index,
              "unit_price",
              Math.max(0, parseFloat(e.target.value))
            )
          }
        />
      ),
    },
    {
      title: "Total Price",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, record, index) => (
        <Input
          type="number"
          value={isNaN(record.total_price) ? 0 : record.total_price}
          disabled
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => (
        <Space>
          <Button
            style={{ backgroundColor: "lightblue" }}
            onClick={() => handleAddRow(tableIndex, index)}
          >
            <PlusOutlined size={25} color={"#fff"} />
          </Button>
          <Button
            style={{ backgroundColor: "red" }}
            onClick={() => handleDeleteRowTable(tableIndex, index)}
            disabled={tableData[tableIndex].columnsdata.length <= 1}
          >
            <DeleteOutlined size={25} color={"#fff"} />
          </Button>
        </Space>
      ),
    },
  ];

  const handleAddRowModal = () => {
    const newRow = {
      key: data.length,
      company: "", // Initialize with Modalcompany
      additionalPrice: "", // Initialize with AdditionalPrice
    };
    setData([...data, newRow]);
    setIsDeleteButtonEnabled(true); // Enable the delete button
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchAllTrmActive({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    if (company_id) {
      setNumber_Field(numberfield?.data);
    }
  }, [company_id, numberfield]);

  useEffect(() => {
    if (location.state) {
      const payload = { id: location.state?.id };
      dispatch(
        fetchViewQuotation({
          payload,
          setLoading: setLoading,
        })
      );
      handleChangeee(location.state?.company_id);

      console.log("editData", location.state);
    }
  }, [location]);

  const handleSubmit = () => {
    const payload = {
      // pdf_type: pdfType,
      company_id: [
        localStorage.getItem("companyName"),
        ...data.map((field) => field.company).filter((val) => val),
      ],
      customer_id: customer_id,
      note: note,
      valid_till: expDate,
      number,
      quotation_type,
      tableData: tableData,
      terms_conditions: TermDecccc,
      payment_term,
      is_lock: is_lock,
      date,
      currency,
      // tax_id: options.map((option) => option.tax),
      is_parent: [
        0,
        ...data.map((field) => (field.company ? "1" : "")).filter(Boolean),
      ],
      additional_price: [
        "0",
        ...data.map((field) => field.additionalPrice).filter(Boolean),
      ],
    };
    // const {
    //   pdf_type,
    //   description,
    //   qty,
    //   unit_type,
    //   unit_price,
    //   terms_conditions,
    // } = payload;

    if (
      localStorage.getItem("companyName") &&
      customer_id.trim() &&
      quotation_type &&
      payment_term.trim() &&
      date &&
      currency &&
      expDate
    ) {
      if (!location.state) {
        dispatch(
          fetchStoreQuotation({
            payload,
            setLoading: setLoading,
          })
        );
        setLoading(true);
      } else {
        dispatch(
          updateStoreQuotation({
            payload: { ...payload, qt_id: location.state.id },
            setLoading: setLoading,
          })
        );
        setLoading(true);
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };

  const HandleCustomerPlusIcon = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddTable = () => {
    setTableData([
      ...tableData,
      {
        columnsdata: [
          {
            s_no: "1",
            description: "",
            qty: 0,
            unit: "",
            unit_price: 0,
            total_price: 0,
          },
        ],
        sub_total: "",
        taxes: [{ tax_id: "", tax_value: "", tax_type: "" }],
        total: "",
        table_title: "",
        sub_title: "",
      },
    ]);
  };
  const handleTableTitleChange = (index, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].table_title = value;
    setTableData(updatedTableData);
  };
  const handleTableSubTitleChange = (index, value) => {
    const updatedTableData = [...tableData];
    updatedTableData[index].sub_title = value;
    setTableData(updatedTableData);
  };

  const DeleteTable = (index) => {
    const newTableData = tableData.filter((_, i) => i !== index);
    setTableData(newTableData);
  };

  const SubmitCSVINcr = () => {
    if (!uploadedData) {
      return alert("Please upload a file");
    }

    const incrementedData = uploadedData.map((table) => {
      const updatedColumnsdata = table.columnsdata.map((row) => {
        const updatedUnitPrice =
          parseFloat(row.unit_price) +
          (parseFloat(row.unit_price) * parseFloat(increaseAmount)) / 100;
        const updatedTotalPrice = updatedUnitPrice * row.qty;
        return {
          ...row,
          unit_price: updatedUnitPrice.toFixed(2),
          total_price: updatedTotalPrice.toFixed(2),
        };
      });

      const updatedSubTotal = updatedColumnsdata
        ?.reduce((acc, row) => acc + parseFloat(row.total_price), 0)
        .toFixed(2);

      return {
        ...table,
        columnsdata: updatedColumnsdata,
        sub_total: updatedSubTotal,
      };
    });

    setTableData(incrementedData);
    closeModal();
  };
  const UploaCSVHandle = () => {
    setModalVisible(true);
  };

  const getSummmaryColumns = [
    {
      title: "Table Title",
      dataIndex: "table_title",
      key: "table_title",
    },
    {
      title: "Tax Price",
      dataIndex: "taxes",
      key: "taxes",
      render: (text, record) => {
        return record?.total > 0 ? record?.total - record?.sub_total || 0 : ""; // Ensure it returns 0 if there are no applicable taxes
      },
    },
    {
      title: "Sub Total",
      dataIndex: "sub_total",
      key: "sub_total",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
  ];

  useEffect(() => {
    if (
      location.state &&
      viewQuotionData &&
      viewQuotionData?.main_quotation &&
      viewQuotionData?.main_quotation?.quo_Refs?.length !== 0
    ) {
      // Extract and format data
      const updatedData = viewQuotionData?.main_quotation?.quo_Refs?.map(
        (item) => {
          console.log("CHakaklekdlshchdirqara", item?.table_tax_id);
          return {
            columnsdata: item.qt_items,
            total: item.table_grand_total,
            table_title: item.table_heading,
            taxes: JSON.parse(item.table_tax_id).map((tax) => {
              return {
                ...tax,
                id: tax.tax_id, // Rename tax_id to id
                tax_value: tax.tax_value, // Rename tax_id to id
                tax_name: tax.tax_name, // Rename tax_id to id
                tax_type: tax.tax_type, // Rename tax_id to id
              };
            }),
            sub_title: item.table_sub_heading,
            sub_total: item.table_sub_total,
          };
        }
      );

      // Set formatted data to state
      console.log("Formatted Data:", updatedData);
      setTableData(updatedData);
    }
    console.log("default Dasta:", viewQuotionData);
  }, [location.state, viewQuotionData]);

  return (
    <>
      <MainCard>
        <Spin spinning={loading ? true : companyData ? false : true}>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={12}>
              <div className="row">
                {location.state && (
                  <div className="col-md-4 my-2">
                    <Stack spacing={1}>
                      <InputLabel
                        htmlFor="AssignRole"
                        style={{ fontSize: "13px" }}
                      >
                        Qoutation Number
                      </InputLabel>
                    </Stack>

                    <ThemeInput
                      style={{
                        width: "100%",
                        height: "54px",
                      }}
                      value={location.state?.quote_num}
                    />
                  </div>
                )}
                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Customer*
                    </InputLabel>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SearchTextSelect
                      handleChangeValue={handleChangee}
                      options={options2}
                      value={customer_id}
                    />
                    <AddIcon
                      style={{ cursor: "pointer" }}
                      onClick={HandleCustomerPlusIcon}
                    />
                  </Stack>
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Quotation Type*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        displayEmpty
                        input={<OutlinedInput />}
                        style={{ height: "52px" }}
                        value={quotation_type}
                        onChange={(e) => setquotation_type(e.target.value)}
                      >
                        {/* You can add your select options here */}
                        <MenuItem disabled value="">
                          <em>Select Quotation Type</em>
                        </MenuItem>

                        <MenuItem value="1">Recieving</MenuItem>
                        <MenuItem value="2">ADHOC</MenuItem>
                        <MenuItem value="3">Quotation Vice</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </div>

                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="text"
                    name="Quotation Serial Number*"
                    value={number}
                    disabled={true}
                    onChange={(e) => setNumber_Field(e.target.value)}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="date"
                    name="Date*"
                    value={date}
                    onChange={(e) => setdate(e.target.value)}
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="date"
                    name="Expiry Date*"
                    value={expDate}
                    onChange={(e) => setExpDate(e.target.value)}
                  />
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Currency*
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth></FormControl>
                  </Stack>

                  <SelectAnt
                    showSearch
                    style={{
                      width: "100%",
                      height: "50px",
                    }}
                    onChange={(value) => setcurrency(value)}
                    tokenSeparators={[","]}
                    options={currencyOption}
                    value={currency}
                    placeholder="Select Currency"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    name="Payment Term*"
                    value={payment_term}
                    placeholder="Payment Term"
                    onChange={(e) => setpayment_term(e.target.value)}
                  />
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      File Lock
                    </InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        displayEmpty
                        input={<OutlinedInput />}
                        style={{ height: "52px" }}
                        value={is_lock}
                        onChange={(e) => {
                          setIsLock(e.target.value);
                        }}
                      >
                        <MenuItem disabled value="">
                          <em>Select</em>
                        </MenuItem>
                        {/* You can add your select options here */}
                        <MenuItem value="1">Yes</MenuItem>
                        <MenuItem value="5">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </div>

                <div className="col-md-4 my-2">
                  <Stack spacing={1}>
                    <InputLabel
                      htmlFor="AssignRole"
                      style={{ fontSize: "13px" }}
                    >
                      Term Conditons Select*
                    </InputLabel>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <SearchTextSelect
                      handleChangeValue={HandleTermCondiotonHadle}
                      options={TermCoditoptions}
                      value={termconditonGet}
                    />
                  </Stack>
                </div>

                <div className="col-md-4 my-2">
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="company">Note*</InputLabel>
                      <FormControl variant="outlined" fullWidth>
                        <TextArea
                          rows={3}
                          placeholder="Enter Note"
                          name="Note*"
                          value={note}
                          allowClear
                          onChange={(e) => setNotee(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                  </Grid>
                </div>
                <div className="col-md-12 my-2">
                  <label className="mr-3">"Terms Conditions"</label>
                  <div style={{ height: "300px", overflowY: "scroll" }}>
                    <ReactQuill
                      theme="snow"
                      value={TermDecccc}
                      onChange={SetTermCondiDescri}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Button
                  className="px-4"
                  size="small"
                  variant="contained"
                  onClick={UploaCSVHandle}
                  style={{
                    backgroundColor: "#ed1d24",
                    padding: "8px",
                    fontFamily: "poppins",
                    marginTop: "17px",
                  }}
                >
                  Upload CSV
                </Button>
              </div>
              {tableData?.map((v, index) => {
                console.log("valuetsable", v); // This will log each item in the array to the console

                return (
                  <div key={index}>
                    <div className="row my-2">
                      <div className="col-md-6 my-2">
                        <ThemeInput
                          name="Table Title"
                          value={v.table_title} // Accessing from map data
                          onChange={(e) =>
                            handleTableTitleChange(index, e.target.value)
                          } // Update title on change
                          placeholder="Title"
                        />
                      </div>
                      <div className="col-md-6 my-2">
                        <ThemeInput
                          name="Sub Title"
                          value={v.sub_title} // Accessing from map data
                          onChange={(e) =>
                            handleTableSubTitleChange(index, e.target.value)
                          } // Update title on change
                          placeholder="Title"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <Table
                        className="rounded"
                        pagination={false}
                        columns={getColumns(index)}
                        dataSource={v?.columnsdata} // Make sure to use the correct dataSource
                        scroll={{ x: 1300 }}
                      />
                    </div>

                    <div className="row d-flex justify-content-between mt-3">
                      <div className="col-md-6 my-2">
                        <ThemeInput
                          name="Sub Total"
                          disabled={true}
                          value={v.sub_total} // Accessing from map data
                          placeholder="Total"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <Table
                        className="rounded"
                        pagination={false}
                        columns={getTaxColumns(index)}
                        dataSource={v?.taxes} // Make sure to use the correct dataSource
                        scroll={{ x: 300 }}
                        style={{ maxWidth: "700px", margin: "0 auto" }} // Set max width and center alignment
                      />
                    </div>

                    <div className="row justify-content-end">
                      <div className="col-md-6 my-2">
                        <ThemeInput
                          name="Total"
                          disabled={true}
                          value={v.total} // Accessing from map data
                          placeholder="Total"
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <Button onClick={AddTable}>Add Table</Button>
                      <Button
                        onClick={() => DeleteTable(index)}
                        style={{ marginRight: "10px" }}
                        disabled={tableData?.length <= 1}
                      >
                        Delete Table
                      </Button>
                    </div>
                  </div>
                );
              })}

              <div
                className="row"
                style={{ display: "flex", justifyContent: "left" }}
              >
                <Table
                  className="rounded"
                  pagination={false}
                  columns={getSummmaryColumns}
                  dataSource={tableData} // Make sure to use the correct dataSource
                  scroll={{ x: 300 }}
                  style={{ maxWidth: "700px", margin: "auto 0" }} // Set max width and center alignment
                />
              </div>

              <h6
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
                Grand Total:{" "}
                {(() => {
                  let grandTotal = tableData?.reduce((acc, table) => {
                    return acc + (parseFloat(table.total) || 0);
                  }, 0);

                  return grandTotal;
                })()}
              </h6>

              <h6
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
                Tax :{" "}
                {tableData?.reduce((acc, table) => {
                  return table?.total > 0
                    ? Number(acc) + (table?.total - table?.sub_total || 0)
                    : ""; // Accumulate the difference for each table
                }, 0)}
              </h6>
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "14px",
                  marginTop: "20px",
                }}
              >
                Sub Total:{" "}
                {(() => {
                  let total = tableData?.reduce((acc, table) => {
                    return acc + (parseFloat(table.sub_total) || 0);
                  }, 0);
                  return total;
                })()}
              </h6>

              <div className="row my-6">
                <div className=" my-2 text-end">
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      onClick={handleSubmit}
                      disableElevation
                      className="px-3"
                      sx={{ marginLeft: "20px" }}
                      size="medium"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {location.state === null
                        ? "Save Quotation"
                        : "Update Quotation"}
                    </Button>
                  </div>

                  <Modal
                    title="Add Multiple Companies"
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <Button type="primary" onClick={handleAddRowModal}>
                      <PlusOutlined /> Add Row
                    </Button>
                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: "Comapany",
                          dataIndex: "company",
                          key: "company",
                          render: (text, record) => (
                            <Stack spacing={0}>
                              <FormControl variant="outlined" fullWidth>
                                <Select
                                  displayEmpty
                                  input={<OutlinedInput />}
                                  style={{ height: "52px" }}
                                  value={record?.company}
                                  onChange={(e) =>
                                    handleCompanyChange(
                                      record.key,
                                      e.target.value
                                    )
                                  }
                                >
                                  <MenuItem value="">
                                    <em>Select Company</em>
                                  </MenuItem>
                                  {companyData &&
                                    companyData?.length > 0 &&
                                    companyData?.map((v, e) => {
                                      return (
                                        <MenuItem value={v.id}>
                                          {v.company_name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </FormControl>
                            </Stack>
                          ),
                        },
                        {
                          title: "Additional Price",
                          dataIndex: "Additional Price",
                          key: "Additional Price",
                          render: (text, record) => (
                            <ThemeInput
                              type="number"
                              value={record.additionalPrice}
                              onChange={(e) =>
                                handleAdditionalPriceChange(
                                  record.key,
                                  e.target.value
                                )
                              }
                            />
                          ),
                        },
                        {
                          title: "Action",
                          key: "action",
                          render: (text, record) => (
                            <Space>
                              <Button
                                onClick={() => handleDeleteRow(record.key)}
                                danger
                                disabled={data.length === 1}
                              >
                                <DeleteOutlined /> Delete
                              </Button>
                            </Space>
                          ),
                        },
                      ]}
                      dataSource={data}
                    />
                  </Modal>
                </div>
              </div>
            </Grid>
          </Grid>
        </Spin>
        <AddCompanyModal open={open} handleClose={handleClose} />
      </MainCard>
      <Modal
        title="Upload CSV"
        visible={modalVisible}
        onCancel={closeModal}
        footer={null}
      >
        <div className="row">
          <div className="col-12 my-2">
            <p
              style={{
                marginLeft: "2px",
                color: "black",
                textAlign: "left",
                marginBottom: "6px",
              }}
              className="colorgray"
            >
              Import CSV
            </p>

            <Upload
              customRequest={({ file, onSuccess, onError }) =>
                handleImageUpload(file, onSuccess, onError)
              }
              id={`image-option`}
              listType="picture"
              maxCount={1}
              accept=".xlsx, .xls"
              showUploadList={true}
            >
              <Button
                fullWidth
                className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
              >
                <UploadOutlined style={{ marginRight: 10 }} /> Upload (Max: 1)
              </Button>
            </Upload>
          </div>
          <div className="col-12">
            <ThemeInput
              name="Increase Amount(%)"
              value={increaseAmount} // Accessing from map data
              onChange={(e) => setIncreaseAmount(e.target.value)}
              placeholder="Amount"
            />
          </div>
          <div>
            <Button
              className="px-4"
              size="large"
              variant="contained"
              onClick={SubmitCSVINcr}
              style={{
                backgroundColor: "#ed1d24",
                padding: "10px",
                fontFamily: "poppins",
                marginTop: "17px",
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    companyData: state?.company?.companyData?.data,
    customerData: state?.customerdata?.customerData?.data,
    currencyData: state?.currency?.currencyData?.data,
    taxes: state?.taxesdata?.taxes?.data,
    numberfield: state?.customernumberfield?.numberfield,
    viewQuotionData: state?.quotationView?.viewQuotation?.data,
    quotationsssactive: state?.quotationssActiveDetail?.quotationsssactive,
  };
};
export default connect(mapStateToProps)(CreateQuotation);
